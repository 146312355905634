const inspirationButton = document.getElementById("inspirationButton");
const body = document.querySelector(".body");
const Filters = document.querySelectorAll(".inspiration__filter-wrapper");

adaptItemHeight();

inspirationButton?.addEventListener('click', function() {
    adaptItemHeight();
});

window.addEventListener('resize', function() {
    adaptItemHeight();
});



function adaptItemHeight() {
    const itemsContainer = document.getElementById('inspiration-row');
    const items = itemsContainer?.getElementsByClassName('card--inspiration');
    let columnCount;

    if (items) {
        if (window.innerWidth > 991) {
            columnCount = 3;
        }   else {
            columnCount = 2;
        }

        const columnHeights = Array(columnCount).fill(0); // track height of each column
        const gap = 12;

        for (let i = 0; i < items.length; i++) {
            const item = items[i] as HTMLElement;

            // find shortest column
            const minColumnHeight = Math.min(...columnHeights);
            const columnIndex = columnHeights.indexOf(minColumnHeight);

            if (itemsContainer) {
                const itemWidthPercent = 100 / columnCount;
                // calculate the left position
                const leftPercent = columnIndex * itemWidthPercent;

                // set item position
                item.style.position = 'absolute';
                item.style.width = `${itemWidthPercent}%`;
                item.style.top = `${minColumnHeight}px`;
                item.style.left = `${leftPercent}%`;

                // update column height
                columnHeights[columnIndex] += item.offsetHeight + gap;
            }
        }

        // set container height
        if (itemsContainer) {
            (itemsContainer as HTMLElement).style.position = 'relative';
            (itemsContainer as HTMLElement).style.height = `${Math.max(...columnHeights)}px`;
        }
    }
}
Filters.forEach(filter => {
    filter.addEventListener("click", function(event) {
        const target = event.target;
        if ((target as HTMLElement).classList.contains("InspirationFilterCheckbox")) {
            adaptItemHeight();
        }
    });
});
