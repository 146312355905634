document.addEventListener('DOMContentLoaded', () => {
    const form = document.querySelector('.Garantieregistrierungsformular');
    if (form) {
        const serialNumberField = document.querySelector('.serialNumber');
        const warningMessage = document.createElement('span');
        warningMessage.style.color = 'red';
        warningMessage.style.display = 'none';
        // @ts-ignore
        warningMessage.textContent = window.TYPO3.lang.serialNumberValidationMessage;

        serialNumberField.parentNode.insertBefore(warningMessage, serialNumberField.nextSibling);

        function validateSerialNumber() {
            const value = serialNumberField.value;
            const regex = /^(L[A-Z0-9]{2,4}|P[A-Z0-9]{2})-[A-Z0-9]+$/i;

            if (!regex.test(value)) {
                warningMessage.style.display = 'block';
                serialNumberField.focus();
                return false;
            } else {
                warningMessage.style.display = 'none';
                return true;
            }
        }

        serialNumberField.addEventListener('blur', validateSerialNumber);

        form.addEventListener('submit', (event) => {
            if (!validateSerialNumber()) {
                event.preventDefault();
            }
        });
    }
});
