export function getProductFilter(){
    // Select all Product and Application checkboxes
    const productCheckboxes = document.querySelectorAll('.ProductFilter');

    // Collect the selected Product filters
    let productFilters: Array<any> = [];
    productCheckboxes.forEach(function(checkbox) {
        if ((checkbox as HTMLInputElement ).checked) {
            productFilters.push(checkbox.getAttribute('data-id'));
        }
    });

    return productFilters;
}

export function getPartnerFilter(){
    // Select all Partner and Application checkboxes
    const partnerCheckboxes = document.querySelectorAll('.PartnerFilter');

    // Collect the selected Product filters
    let partnerFilters: Array<any> = [];
    partnerCheckboxes.forEach(function(checkbox) {
        if ((checkbox as HTMLInputElement ).checked) {
            partnerFilters.push(checkbox.getAttribute('data-id'));
        }
    });

    return partnerFilters;
}

export function getApplicationFilter(){
    const applicationCheckboxes = document.querySelectorAll('.ApplicationFilter');

    // Collect the selected Application filters
    let applicationFilters: Array<any> = [];
    applicationCheckboxes.forEach(function(checkbox) {
        if ((checkbox as HTMLInputElement ).checked) {
            applicationFilters.push(checkbox.getAttribute('data-id'));
        }
    });

    return applicationFilters;
}

export function getCountryFilter(){
    const countryCheckboxes = document.querySelectorAll('.CountryFilter');

    // Collect the selected Country filters
    let countryFilters: Array<any> = [];
    countryCheckboxes.forEach(function(checkbox) {
        if ((checkbox as HTMLInputElement ).checked) {
            countryFilters.push(checkbox.getAttribute('data-id'));
        }
    });

    return countryFilters;
}


export function toggleFilterCheckboxes() {
    const Filters = document.querySelectorAll(".inspiration__filter-wrapper");

    Filters.forEach(filter => {
        const filterHeader = filter.querySelector(".inspiration__filter--header");
        const filterChecks = filter.querySelector(".inspiration__filter--items");

        filterHeader?.addEventListener("click", function() {
            document.querySelectorAll(".inspiration__filter--items").forEach(item => {
                if (item !== filterChecks) {
                    item.classList.add("is__hidden");
                }
            });
            
            filterChecks?.classList.toggle("is__hidden");
        })
    })
}

export function hideFilter() {
    const hideButton = document.querySelector(".inspiration__filter--settings");
    const filter = document.querySelector(".inspiration__filter");
    const filterTexts = document.querySelectorAll(".inspiration__text");

    hideButton?.addEventListener("click", function() {
        if (filter?.classList.contains("is__hidden")) {
            filter?.classList.remove("is__hidden");
        } else {
            filter?.classList.add("is__hidden");
        }

        console.log('clicked');
        
        filterTexts.forEach(text => {
            console.log(text);
            
            text.classList.toggle("d-none");
        });
    });
}
