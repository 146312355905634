setTimeout(() => {
    // Select all country and language selects
    const selects = [
        {
            selectElement: document.querySelector('#langPopup-country'),
            customContainer: document.querySelectorAll('.custom__select--country .custom__select--title'),
            customPreview: document.querySelectorAll('.custom__select--country .custom__select--preview'),
            customOptionsContainer: document.querySelectorAll('.custom__select--country .custom__select--options')
        },
        {
            selectElement: document.querySelector('#langPopup-language'),
            customContainer: document.querySelectorAll('.custom__select--language .custom__select--title'),
            customPreview: document.querySelectorAll('.custom__select--language .custom__select--preview'),
            customOptionsContainer: document.querySelectorAll('.custom__select--language .custom__select--options')
        }
    ];

    // Function to populate custom select box
    function populateCustomSelect(selectElement, customContainer, customOptionsContainer) {
        customOptionsContainer.innerHTML = ''; // Clear existing options
        const options = selectElement.querySelectorAll('option');
        options.forEach(option => {
            // Skip if the option has the langPopup__hide class
            if (option.classList.contains('langPopup__hide')) {
                return;
            }

            const customOption = document.createElement('div');
            customOption.classList.add('custom__select--option');
            customOption.textContent = option.textContent;
            customOption.dataset.value = option.value;
            if (option.selected) {
                customContainer.textContent = option.textContent; // Set the selected option to the custom title
                customOption.classList.add('selected'); // Mark it as selected
            }

            // Handle option click
            customOption.addEventListener('click', function() {
                // Remove "selected" class from all options
                customOptionsContainer.querySelectorAll('.custom__select--option').forEach(opt => opt.classList.remove('selected'));

                // Set this option as selected
                option.selected = true;
                customOption.classList.add('selected');
                customContainer.textContent = option.textContent;

                // Simulate select change event
                selectElement.dispatchEvent(new Event('change'));

                // Close the custom dropdown
                customOptionsContainer.classList.remove('open');
            });

            customOptionsContainer.appendChild(customOption);
        });
    }

    // Loop through select inputs
    selects.forEach(({ selectElement, customContainer, customPreview, customOptionsContainer }) => {
        // Hide the select box
        selectElement.style.display = 'none';

        // Populate the custom select box with options for each instance
        customContainer.forEach((container, index) => {
            populateCustomSelect(selectElement, container, customOptionsContainer[index]);

            // Toggle the custom options on preview click for each instance
            customPreview[index].addEventListener('click', function(e) {
                e.stopPropagation(); // Prevent event from bubbling up to document
                customOptionsContainer[index].classList.toggle('open');
            });
        });
    });

    // Close the dropdown if clicking outside or if an option is selected
    document.addEventListener('click', function(e) {
        selects.forEach(({ customOptionsContainer }) => {
            customOptionsContainer.forEach(optionsContainer => {
                if (!optionsContainer.contains(e.target)) {
                    optionsContainer.classList.remove('open');
                }
            });
        });
    });

    // MutationObserver to detect changes in language select options' class lists
    const languageSelectElement = document.querySelector('#langPopup-language');
    const customLanguageOptionsContainer = document.querySelectorAll('.custom__select--language .custom__select--options');
    const customLanguageContainer = document.querySelectorAll('.custom__select--language .custom__select--title');

    const observer = new MutationObserver(() => {
        // Repopulate the custom language select whenever an option's class list changes
        customLanguageContainer.forEach((container, index) => {
            populateCustomSelect(languageSelectElement, container, customLanguageOptionsContainer[index]);
        });
    });

    // Observe each option's class list changes in the language select
    const languageOptions = languageSelectElement.querySelectorAll('option');
    languageOptions.forEach(option => {
        observer.observe(option, { attributes: true, attributeFilter: ['class'] });
    });

}, 1000);
