import React, { useState } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { Point } from "./Point";
import { Location } from "./Location";
import { SalesPartnerMap } from "./SalesPartnerMap";
import { SearchBoxItem } from "./SearchBoxItem";
import { createRoot } from "react-dom/client";
import { countryMap } from "./Countries";

const queryClient = new QueryClient();

type Country = string;

const fetchCountries = async ({ queryKey }) => {
    let url = `${queryKey[1]}/country`;
    const apiRes = await fetch(url);
    if (!apiRes.ok) {
        throw new Error("Failed to fetch countries");
    }
    return apiRes.json();
};

const fetchLocations = async ({ queryKey }) => {
    let url = `${queryKey[1]}/salespartner`;
    const apiRes = await fetch(url);
    if (!apiRes.ok) {
        throw new Error("Failed to fetch locations");
    }
    return apiRes.json();
};

function SalesPartnerWorldwideApp() {
    const element = document.getElementById("salespartner-world-map");
    const zoomLevel = parseInt(element.dataset.zoomLevel);
    const centerLat = parseFloat(element.dataset.centerLat);
    const centerLng = parseFloat(element.dataset.centerLng);

    return (
        <QueryClientProvider client={queryClient}>
            <SalesPartners zoomLevel={zoomLevel} centerLat={centerLat} centerLng={centerLng}
                           apiUrl={element.dataset.apiUrl} />
        </QueryClientProvider>
    );
}

function SalesPartners({ zoomLevel, centerLat, centerLng, apiUrl }: {
    zoomLevel: number;
    centerLat: number;
    centerLng: number;
    apiUrl: string;
}) {
    const [center, setCenter] = useState<Point>({ lat: centerLat, lng: centerLng });
    const [zoom, setZoom] = useState<number>(zoomLevel);
    const [selectedFilterItem, setSelectedFilterItem] = useState<string | null>(null);

    const [searchCity, setSearchCity] = useState<string>(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        return queryParameters.get("search") ?? "";
    });

    const countriesQuery = useQuery({
        queryKey: ["countries", apiUrl],
        queryFn: fetchCountries
    });

    const locationsQuery = useQuery({
        queryKey: ["locations", apiUrl],
        queryFn: fetchLocations
    });

    if (countriesQuery.isLoading || locationsQuery.isLoading) {
        return (
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        );
    }

    const locations: Location[] = searchCity
        ? locationsQuery.data.data.filter((location) => location.country_code.includes(searchCity))
        : locationsQuery.data.data;

    return (
        <div className="partners-world-map">
            <SalesPartnerMap
                locations={locations}
                center={center}
                setCenter={setCenter}
                zoom={zoom}
                setZoom={setZoom}
                searchCity={searchCity}
                selectedFilterItem={selectedFilterItem}
            />
            <SearchBox
                searchCity={searchCity}
                setSearchCity={setSearchCity}
                locations={locations}
                countries={countriesQuery.data.data}
                setCenter={setCenter}
                setZoom={setZoom}
                setSelectedFilterItem={setSelectedFilterItem}
            />
        </div>
    );
}

function SearchBox({ searchCity, setSearchCity, locations, countries, setCenter, setZoom, setSelectedFilterItem }:{
    searchCity: string;
    setSearchCity: (str: string) => void;
    locations: Location[];
    countries: Country[];
    setCenter: (value: ((prevState: Point) => Point) | Point) => void;
    setZoom: (zoom: number) => void;
    setSelectedFilterItem: (str: string | null) => void;
}): React.ReactNode {

    const handleSearchChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSearchCity(e.target.value);
        setSelectedFilterItem(e.target.value);

        const selectedCountry = countries.find((country) => country.country_code === e.target.value);
        if (selectedCountry) {
            const countryDetails = countryMap[selectedCountry.country_en];
            if (countryDetails) {
                setCenter({ lat: countryDetails.lat, lng: countryDetails.lng });
                setZoom(countryDetails.zoom);
            }
        }
    };

    return (
        <div className={`search-banner ${searchCity !== "" ? "search-banner--hidden" : ""}`}>
            <form className="search-banner__form">
                <div className="search-banner__box">
                    <select
                        value={searchCity}
                        onChange={handleSearchChange}
                    >
                        <option value="">-- Please choose a country --</option>
                        {countries.map((country) => (
                            <option key={country.country_code} value={country.country_code}>
                                {country.country_en}
                            </option>
                        ))}
                    </select>
                </div>
            </form>

            <ul className="search-banner__list">
                {searchCity !== "" &&
                    locations.map((location) => (
                        <SearchBoxItem
                            key={location.uid}
                            location={location}
                            setCenter={setCenter}
                            setZoom={setZoom}
                        />
                    ))
                }
            </ul>
        </div>
    );
}

const rootElement = document.getElementById("salespartner-world-map");
if (rootElement) {
    const root = createRoot(rootElement);
    root.render(<SalesPartnerWorldwideApp />);
}
