const LanguagePopups = document.querySelectorAll(".langPopup");
LanguagePopups.forEach(langPopup => {

    toggleLanguagePopup();

    const CountryFields = langPopup.querySelectorAll("#langPopup-country");

    CountryFields.forEach(countryField => {
        let selectedCountry = countryField.selectedOptions

        showLanguagesByCountry(countryField);

        countryField.addEventListener("change", function () {
            showLanguagesByCountry(countryField);

            setTimeout(() => {
                if (getActiveLanguages(selectedCountry) == 1) {
                    directlyChangeLanguage();

                } else if (getActiveLanguages(selectedCountry) > 1){
                    showLanguagesByCountry(countryField);
                    changeLanguage();
                }
            }, "500");
        });
    })

    changeLanguage();


    // ----------------------------------------------------
    // Functions
    // ----------------------------------------------------

    function toggleLanguagePopup() {
        const body = document.querySelector("body");

        body.addEventListener("click", function (e) {
            if (!langPopup.classList.contains("langPopup__hide")) {

                // Check if the click was outside the langPopup and langPopupes
                let clickInsidelangPopup = false;
                LanguagePopups.forEach(langPopup => {
                    if (e.target == langPopup) {
                        clickInsidelangPopup = true;
                    }
                });

                if (!langPopup.contains(e.target) && !clickInsidelangPopup) {
                    langPopup.classList.add("langPopup__hide");
                }
            }
        });

        const langSwitch = langPopup.parentElement.querySelector(".langSwitch");
        langSwitch.addEventListener("click", function (e) {

            e.stopPropagation();

            if (langPopup.classList.contains("langPopup__hide")) {
                langPopup.classList.remove("langPopup__hide");
            } else {
                langPopup.classList.add("langPopup__hide");
            }
        });

    }

    function changeLanguage() {
        const body = document.querySelector("body");
        const langSelect = langPopup.parentElement.querySelector("#langPopup-language");

        langSelect.addEventListener("change", function () {
            var selectedLanguage = this.options[this.selectedIndex].getAttribute("data-link");
            const url = window.location;
            const pathnameWithoutTrailingSlashes = url.origin.replace(/\/+$/, "");

            if (selectedLanguage) {
                url.href = pathnameWithoutTrailingSlashes + selectedLanguage;
            }
        });
    }

    function directlyChangeLanguage() {
        const selectedCountryValue = langPopup.querySelector(".langPopup__country").value;
        const Languages = langPopup.querySelectorAll(".langPopup__option--language");
        let selectedLanguageLink = '';

        Languages.forEach(language => {
            if (language.value == selectedCountryValue) {
                selectedLanguageLink = language.getAttribute("data-link");
            }
        });

        const url = window.location;
        const pathnameWithoutTrailingSlashes = url.origin.replace(/\/+$/, "");
        if (selectedLanguageLink) {
            url.href = pathnameWithoutTrailingSlashes + selectedLanguageLink;
        }
    }

    function showLanguagesByCountry(countryField) {
        if (countryField) {
            const Languages = countryField.closest(".langPopup__menu").querySelectorAll(".langPopup__option--language");
            const currentCountry = countryField.value;

            Languages.forEach((language) => {
                const languageValue = language.getAttribute("value");

                if (currentCountry != languageValue) {
                    language.classList.add("langPopup__hide");
                } else if (language.classList.contains("langPopup__hide")) {
                    language.classList.remove("langPopup__hide");
                }
            });
        }
    }

    function getActiveLanguages(countryField) {
        const Languages = langPopup.querySelectorAll(".langPopup__option--language");
        let activeLanguages = 0;
        let hiddenLanguages = 0;

        Languages.forEach((language) => {
            if (!language.classList.contains("langPopup__hide")) {
                activeLanguages++;
            } else {
                hiddenLanguages++;
            }
        });
        return activeLanguages;
    }
});
