const productSelect = document.getElementById("product-select");
if (productSelect) {
    const productForm = document.getElementById("product-form");
    productSelect.addEventListener("change", () => {
        productForm.submit();
    });
}

const variantSelect = document.getElementById("product-variant");
if (variantSelect) {
    const productForm = document.getElementById("product-form");
    variantSelect.addEventListener("change", () => {
        productForm.submit();
    });
}

const filterReset = document.getElementById("form-filter-reset");
if (filterReset) {
    filterReset.addEventListener("click", () => {
        const productForm = document.getElementById("product-filter-form");
        productForm?.reset();
        document.getElementById("search").value = "";
    });
}
