import React, { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { Point } from "./Point";
import { Location } from "./Location";
import { SalesPartnerMap } from "./SalesPartnerMap";
import { SearchBoxItem } from "./SearchBoxItem";

const queryClient = new QueryClient();

interface APIResponse {
    data: Location[];
}

const fetchLocations = async ({ queryKey }) => {
    let url = queryKey[1];
    const term = queryKey[2] ?? null;
    if (term) {
        url = `${url}?searchTerm=${term}`;
    }
    const apiRes = await fetch(url);
    if (!apiRes.ok) {
        throw new Error(`Failed to fetch locations for ${queryKey}`);
    }
    return apiRes.json();
};

export function SalesPartnerApp() {
    const element = document.getElementById("salespartner-map");
    if (element) {
        const zoomLevel = parseInt(element.dataset.zoomLevel);
        const centerLat = parseFloat(element.dataset.centerLat);
        const centerLng = parseFloat(element.dataset.centerLng);

        return (
            <QueryClientProvider client={queryClient}>
                <SalesPartner
                    apiUrl={element.dataset.apiUrl}
                    zoomLevel={zoomLevel}
                    centerLat={centerLat}
                    centerLng={centerLng}
                />
            </QueryClientProvider>
        );
    }
}

function SalesPartner(props: {
    apiUrl: string | undefined;
    zoomLevel: number;
    centerLat: number;
    centerLng: number;
}): React.ReactNode {
    const { apiUrl, zoomLevel, centerLat, centerLng } = props;

    const [searchTerm, setSearchTerm] = useState<string>(() => {
        const queryParameters = new URLSearchParams(window.location.search);
        return queryParameters.get("search") ?? "";
    });

    const [center, setCenter] = useState<Point>({ lat: centerLat, lng: centerLng });
    const [zoom, setZoom] = useState<number>(zoomLevel);
    const [selectedFilterItem, setSelectedFilterItem] = useState<string | null>(null);

    const results = useQuery<APIResponse>({
        queryKey: ["locations", apiUrl, searchTerm],
        queryFn: fetchLocations,
        enabled: !!apiUrl,
        keepPreviousData: true,
    });

    if (results.isLoading) {
        return (
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        );
    }

    const locations: Location[] = results.data?.data ?? [];

    return (
        <div className="partners-map">
            <SalesPartnerMap
                locations={locations}
                center={center}
                setCenter={setCenter}
                zoom={zoom}
                setZoom={setZoom}
                selectedFilterItem={selectedFilterItem}
            />
            <SearchBox
                locations={locations}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                setCenter={setCenter}
                setZoom={setZoom}
                setSelectedFilterItem={setSelectedFilterItem}
            />
        </div>
    );
}

function SearchBox(props: {
    locations: Location[];
    searchTerm: string;
    setSearchTerm: (value: string) => void;
    setCenter: (value: Point) => void;
    setZoom: (zoom: number) => void;
    setSelectedFilterItem: (value: string | null) => void;
}): React.ReactNode {
    const { locations, searchTerm, setSearchTerm, setCenter, setZoom, setSelectedFilterItem } = props;
    const [isListVisible, setIsListVisible] = useState<boolean>(false);

    const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData = new FormData(e.target as HTMLFormElement);
        const searchTerm = formData.get("searchTerm")?.toString() ?? "";
        setSearchTerm(searchTerm);
        setSelectedFilterItem(searchTerm);
        setIsListVisible(true);
    };

    const resetFilter = () => {
        document.querySelector<HTMLInputElement>(".search-banner__input")?.setAttribute("value", "");
        setSearchTerm("");
        document.querySelectorAll(".highlight").forEach(el => el.classList.remove("highlight"));
    };

    useEffect(() => {
        if (searchTerm === "") setIsListVisible(false);
    }, [searchTerm]);

    return (
        <div className={`search-banner ${isListVisible ? 'search-banner--hidden' : ''}`}>
            <form className="search-banner__form" onSubmit={handleSubmit}>
                <div className="search-banner__box">
                    <input
                        className="search-banner__input"
                        id="searchTerm"
                        type="text"
                        name="searchTerm"
                        // @ts-ignore
                        placeholder={window.TYPO3.lang.mapsPlaceholder}
                    />
                    <button className="search-banner__btn" type="submit">
                        <svg className="search-banner__icon" viewBox="0 0 24 24" fill="none">
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M17.0392 15.6244C18.2714 14.084 19.0082 12.1301 19.0082 10.0041C19.0082 5.03127 14.9769 1 10.0041 1C5.03127 1 1 5.03127 1 10.0041C1 14.9769 5.03127 19.0082 10.0041 19.0082C12.1301 19.0082 14.084 18.2714 15.6244 17.0392L21.2921 22.707C21.6828 23.0977 22.3163 23.0977 22.707 22.707C23.0977 22.3163 23.0977 21.6828 22.707 21.2921L17.0392 15.6244ZM10.0041 17.0173C6.1308 17.0173 2.99087 13.8774 2.99087 10.0041C2.99087 6.1308 6.1308 2.99087 10.0041 2.99087C13.8774 2.99087 17.0173 6.1308 17.0173 10.0041C17.0173 13.8774 13.8774 17.0173 10.0041 17.0173Z"
                            />
                        </svg>
                    </button>
                </div>
            </form>

            {searchTerm && (
                <>
                    <div className="search-banner__reset" onClick={resetFilter}>
                        {searchTerm}
                        <svg width="16" height="16" fill="currentColor">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                        </svg>
                    </div>

                    <ul className="search-banner__list">
                        {locations.map(location => (
                            <SearchBoxItem
                                key={location.uid}
                                location={location}
                                setCenter={setCenter}
                                setZoom={setZoom}
                            />
                        ))}
                    </ul>
                </>
            )}
        </div>
    );
}

const rootId = document.getElementById("salespartner-map");
if (rootId) {
    const root = createRoot(rootId);
    root.render(<SalesPartnerApp />);
}
