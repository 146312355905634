import lightGallery from "lightgallery";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

let galleryVideos = document.querySelectorAll(".gallery-videos-demo");
galleryVideos.forEach((item) => {
    lightGallery(item, {
        plugins: [lgVideo],
        selector: ".media__click",
    });
});

let produktVideos = document.querySelectorAll(".produktvideos__item");
produktVideos.forEach((item) => {
    lightGallery(item, {
        plugins: [lgVideo],
        selector: ".el",
    });
});

let inspirationVideos = document.querySelectorAll(".inspiration__video-wrapper");
inspirationVideos.forEach((item) => {
    lightGallery(item, {
        plugins: [lgVideo],
        selector: ".media__click--tall",
    });
});

let tutorialVideos = document.querySelectorAll(".card__image-wrapper--tutorial");
tutorialVideos.forEach((item) => {
    lightGallery(item, {
        plugins: [lgVideo],
        selector: ".media__click--tall",
    });
});

function initializeLightGallery() {
    let tutorialVideos = document.querySelectorAll(".card__image-wrapper--tutorial");

    tutorialVideos.forEach((item) => {
        lightGallery(item, {
            plugins: [lgVideo],
            selector: ".media__click--tall",
        });
    });
}
initializeLightGallery()

const body = document.querySelector("body");
if (tutorialVideos) {
    body.addEventListener("click", function() {
        setTimeout(() => {
            initializeLightGallery();
        }, 100);
    });
}


const btnsShow = document.querySelectorAll('.media__btn');

btnsShow.forEach(btn => {
  btn.addEventListener('click', (e) => {
    let mediaBottom = btn.closest('.media-text');
    let mediaText = mediaBottom.querySelector('.media__text');
    let dataId = mediaText.getAttribute('data-language');
    let text = btn.querySelector('span').innerHTML;
// SP
    if(dataId === '8' && text === 'Leer más') {btn.querySelector('span').innerHTML = 'Mostrar menos'}
    if(dataId === '8' && text === 'Mostrar menos') {btn.querySelector('span').innerHTML = 'Leer más'}
// DE
    if(dataId === '6' && text === 'Weiterlesen') {btn.querySelector('span').innerHTML = 'Weniger anzeigen'}
    if(dataId === '6' && text === 'Weniger anzeigen') {btn.querySelector('span').innerHTML = 'Weiterlesen'}
    if(dataId === '4' && text === 'Weiterlesen') {btn.querySelector('span').innerHTML = 'Weniger anzeigen'}
    if(dataId === '4' && text === 'Weniger anzeigen') {btn.querySelector('span').innerHTML = 'Weiterlesen'}
    if(dataId === '1' && text === 'Weiterlesen') {btn.querySelector('span').innerHTML = 'Weniger anzeigen'}
    if(dataId === '1' && text === 'Weniger anzeigen') {btn.querySelector('span').innerHTML = 'Weiterlesen'}
// NL
    if(dataId === '11' && text === 'Meer leren') {btn.querySelector('span').innerHTML = 'Minder tonen'}
    if(dataId === '11' && text === 'Minder tonen') {btn.querySelector('span').innerHTML = 'Meer leren'}
    if(dataId === '3' && text === 'Meer leren') {btn.querySelector('span').innerHTML = 'Minder tonen'}
    if(dataId === '3' && text === 'Minder tonen') {btn.querySelector('span').innerHTML = 'Meer leren'}
// IT
    if(dataId === '10' && text === 'Per saperne di più') {btn.querySelector('span').innerHTML = 'Mostra meno'}
    if(dataId === '10' && text === 'Mostra meno') {btn.querySelector('span').innerHTML = 'Per saperne di più'}
// FR
    if(dataId === '9' && text === 'En savoir plus') {btn.querySelector('span').innerHTML = 'Afficher moins'}
    if(dataId === '9' && text === 'Afficher moins') {btn.querySelector('span').innerHTML = 'En savoir plus'}
    if(dataId === '2' && text === 'En savoir plus') {btn.querySelector('span').innerHTML = 'Afficher moins'}
    if(dataId === '2' && text === 'Afficher moins') {btn.querySelector('span').innerHTML = 'En savoir plus'}
    if(dataId === '5' && text === 'En savoir plus') {btn.querySelector('span').innerHTML = 'Afficher moins'}
    if(dataId === '5' && text === 'Afficher moins') {btn.querySelector('span').innerHTML = 'En savoir plus'}
// EN
    if(dataId === '0' && text === 'Read more') {btn.querySelector('span').innerHTML = 'Show less'}
    if(dataId === '0' && text === 'Show less') {btn.querySelector('span').innerHTML = 'Read more'}
    if(dataId === '7' && text === 'Read more') {btn.querySelector('span').innerHTML = 'Show less'}
    if(dataId === '7' && text === 'Show less') {btn.querySelector('span').innerHTML = 'Read more'}
    if(dataId === '12' && text === 'Read more') {btn.querySelector('span').innerHTML = 'Show less'}
    if(dataId === '12' && text === 'Show less') {btn.querySelector('span').innerHTML = 'Read more'}

    let icon = mediaBottom.querySelector('.media__icon');
    mediaText?.classList.toggle('media__text--hidden')
    icon.classList.toggle('media__icon--rotate')

  })
})

