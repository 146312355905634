import { AJAX_LIST, ajaxFetch } from "../../Helper/Ajax";

(() => {
    const loadMoreButton = document.querySelector(
        "#tx-smediafaircalendar-list button.load-more",
    ) as HTMLButtonElement | null;



    if (loadMoreButton === null) {
        return;
    }

    loadMoreButton.onclick = async (e) => {
        e.preventDefault();

        const button = e.currentTarget as HTMLButtonElement;
        let { page, storagepids } = button.dataset as any;
        page = parseInt(page) + 1;

        let node = document.querySelector("script#SMEDIAFAIRCALENDAR_LIST_HAS_FURTHER_RECORDS") as HTMLScriptElement;
        if (node !== null) {
            node.parentNode?.removeChild(node);
        }

        const response = await ajaxFetch(AJAX_LIST.SMEDIAFAIRCALENDAR_AJAX_LOADMOREBUTTON, {
            body: JSON.stringify({
                storagepids: storagepids,
                page: page,
            }),
        });

        const text = await response.text();
        const buttonWrapper = document.querySelector("#button-wrapper") as HTMLDivElement;
        buttonWrapper.insertAdjacentHTML("beforebegin", text);

        node = document.querySelector("script#SMEDIAFAIRCALENDAR_LIST_HAS_FURTHER_RECORDS") as HTMLScriptElement;
        eval(node.textContent as string);
        if (window["SMEDIAFAIRCALENDAR_LIST_HAS_FURTHER_RECORDS"] === 0) {
            buttonWrapper.removeChild(button);
        }

        button.setAttribute("data-page", page.toString());
    };
})();
