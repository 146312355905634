import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    AdvancedMarker,
    APIProvider,
    Map,
    MapCameraChangedEvent,
    useAdvancedMarkerRef,
    useMap
} from "@vis.gl/react-google-maps";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Point } from "./Point";
import { Location } from "./Location";

// API keys
const API_KEY = globalThis.GOOGLE_MAPS_API_KEY ?? process.env.GOOGLE_MAPS_API_KEY!;
const MAP_ID = globalThis.GOOGLE_MAPS_MAP_ID ?? process.env.GOOGLE_MAPS_MAP_ID!;

export function SalesPartnerMap({
    locations,
    center,
    setCenter,
    zoom,
    setZoom,
    selectedFilterItem
}: {
    locations: Location[];
    center: Point;
    setCenter: React.Dispatch<React.SetStateAction<Point>>;
    zoom: number;
    setZoom: React.Dispatch<React.SetStateAction<number>>;
    selectedFilterItem: string | null;
}): JSX.Element {
    // Filter valid locations
    const validLocations = locations.filter(
        (location) => location.latitude !== null && location.longitude !== null
    );

    // Handle map camera change event
    const handleCameraChange = useCallback(
        (ev: MapCameraChangedEvent) => {
            setCenter(ev.detail.center);
            setZoom(ev.detail.zoom);
        },
        [setCenter, setZoom]
    );

    return (
        <APIProvider apiKey={API_KEY} libraries={["marker"]}>
            <Map
                mapId={MAP_ID}
                defaultZoom={zoom}
                defaultCenter={{ lat: center.lat, lng: center.lng }}
                center={center}
                zoom={zoom}
                gestureHandling="greedy"
                disableDefaultUI
                style={{ width: "100%" }}
                className="map-inner"
                onCameraChanged={handleCameraChange}
            >
                <Markers locations={validLocations} selectedFilterItem={selectedFilterItem}/>
            </Map>
        </APIProvider>
    );
}

function Markers({ locations, selectedFilterItem}: { locations: Location[], selectedFilterItem: String | null }): JSX.Element {
    const map = useMap();
    const [markers, setMarkers] = useState<Record<string, google.maps.Marker>>({});
    const clusterer = useRef<MarkerClusterer | null>(null);

    // Initialize the MarkerClusterer when the map is ready
    useEffect(() => {
        if (map && !clusterer.current) {
            clusterer.current = new MarkerClusterer({ map });
        }
    }, [map]);

    // Update the MarkerClusterer whenever the markers change (this includes filtering)

    const setMarkerRef = useCallback(
        (marker: google.maps.Marker | null, key: string) => {
            setMarkers((prev) => {
                // Avoid unnecessary re-renders if the marker is already set
                if (marker && prev[key] === marker) {
                    return prev;
                }
                const newMarkers = { ...prev };
                if (marker) {
                    newMarkers[key] = marker; // Add the new marker to the state
                } else {
                    delete newMarkers[key]; // Remove the marker if it's null
                }
                return newMarkers;
            });
        },
        []
    );
    useEffect(() => {
        if (clusterer.current) {
            // Clear the current markers from the clusterer
            clusterer.current.clearMarkers();

            for (const location of locations) {
                const marker = markers[location.uid];
                if (marker) {
                    clusterer.current.addMarker(marker);
                }
            }
        }
    }, [markers, selectedFilterItem]); // Add `locations` as a dependency to trigger when filtering occurs


    return (
        <>
            {locations.map((location) => (
                <MarkerWithInfoWindow key={location.uid} location={location} setMarkerRef={setMarkerRef} />
            ))}
        </>
    );
}

function MarkerWithInfoWindow({
    location,
    setMarkerRef
}: {
    location: Location;
    setMarkerRef: (marker: google.maps.Marker | null, key: string) => void;
}): JSX.Element {
    const [advancedMarkerRef] = useAdvancedMarkerRef();

    const handleMarkerClick = useCallback((event: google.maps.MapMouseEvent) => {
        const infoWindow = event.domEvent.target.closest(".info-window");
        if (!infoWindow) return;

        const infoTitle = infoWindow.querySelector(".name")?.textContent;
        const infoAddress = infoWindow.querySelector(".address")?.textContent;
        const infoIdentifier = `${infoTitle}${infoAddress}`;

        const allInfoWindows = document.querySelectorAll(".info-window");
        const allBoxes = document.querySelectorAll(".search-card");
        const searchBanner = document.querySelector(".search-banner");
        const listClone = document.querySelector(".search-banner__listclone");

        // Remove previous clones if they exist
        listClone?.remove();

        // Add new search card if necessary
        if (!infoWindow.classList.contains("highlight") && !searchBanner.querySelector(".search-banner__list")) {
            addSearchCardClone();
        }

        // Close all highlighted windows
        const closeAllWindows = () => allInfoWindows.forEach((element) => element.classList.remove("highlight"));

        // Toggle current info window and highlight corresponding search card
        if (!infoWindow.classList.contains("highlight")) {
            closeAllWindows();
            infoWindow.classList.add("highlight");
            highlightSearchBox(allBoxes, infoIdentifier);
        } else {
            closeAllWindows();
        }

        function addSearchCardClone() {
            const newSearchCard = document.createElement("ul");
            newSearchCard.className = "search-banner__listclone";
            newSearchCard.innerHTML = `
                <li class="search-card">
                    <h4 class="search-card__title">${location.name}</h4>
                    <span class="search-card__address">${location.address}</span>
                    <div class="search-card__adresse">
                        <span>${location.city}</span>
                    </div>
                    <div class="search-card__phone">
                        ${location.phone}
                    </div>
                    <div class="search-card__email">
                        ${location.email}
                    </div>

                    <a class="search-card__link" href="${location.website1}" target="_blank">
                        Website
                    </a>
                </li>
            `;
            searchBanner?.appendChild(newSearchCard);
        }

        function highlightSearchBox(allBoxes: NodeListOf<Element>, infoIdentifier: string) {
            allBoxes.forEach((box) => {
                const boxTitle = box.querySelector(".search-card__title")?.textContent;
                const boxAddress = box.querySelector(".search-card__address")?.textContent;
                const boxIdentifier = `${boxTitle}${boxAddress}`;

                if (boxIdentifier === infoIdentifier) {
                    allBoxes.forEach((box) => box.classList.remove("highlight"));
                    box.classList.add("highlight");
                }
            });
        }
    }, []);

    return (
        <AdvancedMarker
            position={{ lat: location.latitude, lng: location.longitude }}
            ref={(marker) => {
                if (marker) {
                    advancedMarkerRef(marker);
                    setMarkerRef(marker, location.uid);
                }
            }}
            title={location.name}
            onClick={handleMarkerClick}
        >
            <div className="info-window">
                <svg viewBox="0 0 512 512">
                    <path
                        d="M390.54,55.719C353.383,18.578,304.696,0,255.993,0c-48.688,0-97.391,18.578-134.547,55.719
                        c-59.219,59.219-74.641,149.563-36.094,218.875C129.586,354.109,255.993,512,255.993,512s126.422-157.891,
                        170.656-237.406C465.195,205.281,449.773,114.938,390.54,55.719z M255.993,305.844c-63.813,0-115.563-51.75-115.563-115.547
                        c0-63.859,51.75-115.609,115.563-115.609c63.828,0,115.578,51.75,115.578,115.609C371.571,254.094,319.821,305.844,255.993,305.844z"
                    />
                </svg>
                <div className="details">
                    <button className="close">&#9587;</button>
                    <div className="name">{location.name}</div>
                    <div className="address">{location.address}</div>
                    <div className="city">{location.postcode} {location.city}</div>
                    <div className="phone">{location.phone}</div>
                    {location.website1 && (
                        <div>
                            <a className="link" href={location.website1} target="_blank">
                                Website
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </AdvancedMarker>
    );
}
