import Swiper from "swiper";
import { Navigation, Thumbs } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

var thumbSwiper = new Swiper(".productswiper__thumbs", {

    spaceBetween: 8,
    observer: true,
    observeParents: true,
    watchSlidesProgress: true,
    // centeredSlides: true,
    slideToClickedSlide: true,
    freeMode:true,
    breakpoints: {
        1199: {
          slidesPerView: 7,
        },
        767: {
          slidesPerView: 3,
        },
        600: {
          slidesPerView: 2,
        },
    },
});

var gallerySwiper = new Swiper(".productswiper", {
    modules: [Navigation, Thumbs],
    spaceBetween: 10,
    observer: true,
    observeParents: true,
    navigation: {
        nextEl: ".product__button.product__button-next",
        prevEl: ".product__button.product__button-prev",
    },
    thumbs: {
      swiper: thumbSwiper,
    },
});

gallerySwiper.on("slideChange", () => {
    thumbSwiper.slideTo(gallerySwiper.activeIndex);
});

thumbSwiper.on("slideChange", () => {
    gallerySwiper.slideTo(thumbSwiper.activeIndex);
});

thumbSwiper.on("click", () => {
    gallerySwiper.slideTo(thumbSwiper.clickedIndex);
    thumbSwiper.slideTo(thumbSwiper.clickedIndex);
});


