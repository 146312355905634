const form = document.querySelector(".contactformular");
if (form) {

    const url = new URL(window.location.href);
    const contactParam = url.searchParams.get("contact");

    const radioButtons = document.querySelectorAll(".radio-buttons");

    document.querySelector('input[type="checkbox"].newsletter-check').checked = true;

    function toggleFieldState(showFields) {
        const hiddenFields = document.querySelectorAll(".hidden-element");

        hiddenFields.forEach(field => {
            const fieldGroup = field.closest(".form-group");
            const label = fieldGroup.querySelector("label");

            if (!label.dataset.defaultText) {
                label.dataset.defaultText = label.textContent.replace(" *", "");
            }
            const defaultLabelText = label.dataset.defaultText;

            if (showFields) {
                fieldGroup.style.display = "block";
                field.setAttribute("required", "required");
                if (!label.textContent.includes(" *")) {
                    label.textContent = defaultLabelText + " *";
                }
            } else {
                fieldGroup.style.display = "none";
                field.removeAttribute("required");
                label.textContent = defaultLabelText;
            }
        });
    }

    function toggleCheckboxes(showCheckboxes) {
        const hiddenCheckboxes = document.querySelectorAll(".checkbox-buttons");

        hiddenCheckboxes.forEach(checkbox => {
            const checkboxGroup = checkbox.closest(".form-group");
            checkboxGroup.style.display = showCheckboxes ? "block" : "none";
            checkbox.disabled = !showCheckboxes;
        });
    }

    function togglePhoneField(showPhone) {
        const phoneField = document.querySelector(".hidden-element-phone");

        if (phoneField) {
            const fieldGroup = phoneField.closest(".form-group");
            if (showPhone) {
                fieldGroup.style.display = "block";
            } else {
                fieldGroup.style.display = "none";
            }
        }
    }

    toggleCheckboxes(false);
    toggleFieldState(true);
    togglePhoneField(true);

    radioButtons[0].addEventListener("change", function() {
        toggleFieldState(true);
        toggleCheckboxes(false);
        togglePhoneField(true);
    });

    radioButtons[1].addEventListener("change", function() {
        toggleFieldState(false);
        toggleCheckboxes(true);
        togglePhoneField(false);
    });

    if (contactParam === "Order") {
        radioButtons[1].checked = true;
        radioButtons[1].dispatchEvent(new Event("change"));
    }
}
